.skip-link {
  position: fixed;
  bottom: -10000px;
  left: -100000px;
  border-radius: 50px;
  border: 4px solid white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.03));
  min-width: 152px;
  min-height: 57px;
  padding: 20px;
  background:  #6310bc;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 1000;
  align-items: center;
  display: flex;
  color: white;

}
.skip-link:focus {
  visibility: visible; /* Visible when focused */
  outline: 2px solid #fff;
  bottom: 10px;
  left: 10px;
}